<template>
  <v-container>
    <v-row>
      <!-- <v-btn @click="openHostedCheckout()" color="green" outlined>Renew</v-btn> -->
    </v-row>

    <!-- <form METHOD="post" ACTION="https://secure.OGONE.com/ncol/test/order.asp" id="form1" name="form1"> -->
    <!-- <form
      METHOD="post"
      ACTION="https://ogone.test.v-psp.com/ncol/test/orderstandard_utf8.asp"
      id="form1"
      name="form1"
    > -->
    <!-- target="checkoutIFrame" -->
    <form
      METHOD="post"
      ACTION="https://secure.ogone.com/ncol/prod/orderstandard_utf8.asp"
      id="form1"
      name="form1"
      target="checkoutIFrame"
    >
      <!-- target="checkoutIFrame" -->
      <input TYPE="hidden" NAME="orderID" :VALUE="orderId" />
      <input TYPE="hidden" NAME="pspid" :VALUE="PSPID" />
      <input TYPE="hidden" NAME="language" :VALUE="language" />
      <input TYPE="hidden" NAME="currency" :VALUE="currency" />
      <input TYPE="hidden" NAME="amount" :VALUE="amount" />
      <input TYPE="hidden" NAME="SHASign" :VALUE="shasign" />

      <!-- <input TYPE="hidden" NAME="version" VALUE="ncol_2.0" /> -->
      <!-- <input TYPE="hidden" NAME="accepturl" VALUE="http://localhost:8443/#/payments" />
      <input TYPE="hidden" NAME="exceptionurl" VALUE="http://localhost:8443/#/payments" />
      <input TYPE="hidden" NAME="declineurl" VALUE="http://localhost:8443/#/payments" />
      <input TYPE="hidden" NAME="cancelurl" VALUE="http://localhost:8443/#/payments" />
      <input TYPE="hidden" NAME="homeurl" VALUE="http://localhost:8443/" /> -->

      <v-btn TYPE="submit" VALUE="<Fill with your prompt>" color="green" outlined>Renew</v-btn>
    </form>

    <iframe name="checkoutIFrame" width="100%" height="700" style="border: none"></iframe>
  </v-container>
</template>

<script>
import axios from "axios";
import Bus from "@/bus";
import IngenicoApi from "@/api/IngenicoApi";
// import PaymentReceipt from "@/components/Payments/PaymentReceipt";
import sha512 from "js-sha512";

export default {
  components: {},
  name: "Payment",

  data: () => ({
    hostedCheckoutURL: "",
    isShowHostedCheckoutIFrame: false,
    createdPaymentOutput: {},
    ingenicoApi: new IngenicoApi(),
    amount: 1234,
    orderId: 9,
    returnHTML: "",
    // PSPID: "SIGNISTEST",
    PSPID: "SIGNIS",
    currency: "EUR",
    language: "en_US",
  }),
  computed: {
    form() {
      return {
        amount: this.amount,
        currency: this.currency,
        language: this.language,
        orderID: this.orderId,
        pspid: this.PSPID,
        SHASign: this.shasign,
      };
    },
    shasign() {
      // const secretSignature = "f242f125-e9c1-4878-a4c8-c8aa370db9a5";
      const secretSignature = "8ad04cc4-e185-42b2-9c57-249dfc78538e";

      var tempSHASIGN = `AMOUNT=${this.amount}${secretSignature}CURRENCY=${this.currency}${secretSignature}LANGUAGE=${this.language}${secretSignature}ORDERID=${this.orderId}${secretSignature}PSPID=${this.PSPID}${secretSignature}`;
      // "AMOUNT=1234f242f125-e9c1-4878-a4c8-c8aa370db9a5CURRENCY=EURf242f125-e9c1-4878-a4c8-c8aa370db9a5LANGUAGE=en_USf242f125-e9c1-4878-a4c8-c8aa370db9a5ORDERID=1f242f125-e9c1-4878-a4c8-c8aa370db9a5PSPID=SIGNISTESTf242f125-e9c1-4878-a4c8-c8aa370db9a5"
      // my output
      // 9C7049EA2174C61C6170FF966746AA78C4C0B290D381D61C533D1420C086DECF30BFCED4B9C020BB346351F7F281A425012D77C71FBE4DAD5E203ED85CB59243

      // ingenico output
      // 9C7049EA2174C61C6170FF966746AA78C4C0B290D381D61C533D1420C086DECF30BFCED4B9C020BB346351F7F281A425012D77C71FBE4DAD5E203ED85CB59243

      var shasign = sha512(tempSHASIGN).toUpperCase();

      return shasign;
    },
  },

  methods: {
    async openHostedCheckout() {
      const formData = new URLSearchParams();
      Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key]);
      });

      axios({
        method: "post",
        url: "https://ogone.test.v-psp.com/ncol/test/orderstandard_utf8.asp",
        // url: "https://secure.ogone.com/ncol/prod/orderstandard_utf8.asp",
        data: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "Access-Control-Allow-Origin":
            "https://ogone.test.v-psp.com/ncol/test/orderstandard_utf8.asp",
        },
      })
        .then(function (response) {
          //handle success
          console.log(response.data);
          this.returnHTML = response.data;
          this.isShowHostedCheckoutIFrame = true;
        })
        .catch(function (response) {
          //handle error
          console.log(response);
        });
      // const result = await this.ingenicoApi.hostedCheckout(this.renewData);
      // if (result.error) {
      //   Bus.$emit("alert", "error", result.error);
      //   this.isShowHostedCheckoutIFrame = false;
      //   return;
      // }
      // this.hostedCheckoutURL = result;
      // this.isShowHostedCheckoutIFrame = true;
    },

    async getCheckoutStatus(returnMAC, hostedCheckoutId) {
      var hostedCheckoutData = {
        userId: 1,
        returnMAC: returnMAC,
        hostedCheckoutId: hostedCheckoutId,
      };

      const result = await this.ingenicoApi.getCheckoutStatus(hostedCheckoutData);
      if (result.error) {
        Bus.$emit("alert", "error", result.error);
        return;
      }
      if (result.status) {
        switch (result.status) {
          case "CANCELLED_BY_CONSUMER":
            Bus.$emit("alert", "error", "Payment Cancelled!");
            break;
          case "CLIENT_NOT_ELIGIBLE_FOR_SELECTED_PAYMENT_PRODUCT":
            Bus.$emit(
              "alert",
              "warning",
              "Client not eligible for selected payment product. Payment Cancelled!"
            );
            break;
          case "IN_PROGRESS":
            Bus.$emit("alert", "warning", "Payment Pending!");
            break;
          case "PAYMENT_CREATED":
            this.createdPaymentOutput = result.createdPaymentOutput;
            switch (this.createdPaymentOutput.paymentStatusCategory) {
              case "SUCCESSFUL":
                switch (this.createdPaymentOutput.payment.statusOutput.statusCategory) {
                  case "REVERSED":
                    Bus.$emit("alert", "error", "Payment Unsuccessful. Payment Reversed.");
                    break;
                  case "REFUNDED":
                    Bus.$emit("alert", "error", "Payment Unsuccessful. Payment Refuned.");
                    break;
                  default:
                    this.$refs.paymentReceipt.open();
                    Bus.$emit("alert", "success", "Payment Successful!");
                    break;
                }
                break;
              case "REJECTED":
                Bus.$emit("alert", "error", "Payment Rejected!");
                break;
              case "STATUS_UNKNOWN":
                Bus.$emit("alert", "warning", "Payment Status Unknown. Check back later.");
                break;

              default:
                break;
            }

            break;
          default:
            break;
        }
      }
      // parameters userid, RETURNMAC, hostedCheckoutId
      // getHostedCheckoutStatus()
      // //Should save to db username, returnmac, hostedCheckoutId, status, amount paid, credit card, payment id
      // createdPaymentOutput: {
      //   displayedData: null,
      //   payment: {
      //     hostedCheckoutSpecificOutput: {
      //       hostedCheckoutId: "6085a083-f3d4-4b64-96e0-c7a1be525252",
      //       variant: "100",
      //     },
      //     paymentOutput: {
      //       amountPaid: null,
      //       amountReversed: null,
      //       bankTransferPaymentMethodSpecificOutput: null,
      //       cardPaymentMethodSpecificOutput: {
      //         authorisationCode: "OK2334",
      //         card: { cardNumber: "************0026", expiryDate: "0222" },
      //         fraudResults: {
      //           avsResult: "Y",
      //           cvvResult: "0",
      //           fraugster: null,
      //           retailDecisions: null,
      //           fraudServiceResult: "no-advice",
      //           inAuth: null,
      //         },
      //         initialSchemeTransactionId: null,
      //         schemeTransactionId: null,
      //         threeDSecureResults: null,
      //         token: null,
      //         paymentProductId: 1,
      //       },
      //       cashPaymentMethodSpecificOutput: null,
      //       directDebitPaymentMethodSpecificOutput: null,
      //       eInvoicePaymentMethodSpecificOutput: null,
      //       invoicePaymentMethodSpecificOutput: null,
      //       mobilePaymentMethodSpecificOutput: null,
      //       paymentMethod: "card",
      //       redirectPaymentMethodSpecificOutput: null,
      //       reversalReason: null,
      //       sepaDirectDebitPaymentMethodSpecificOutput: null,
      //       amountOfMoney: { amount: 3500, currencyCode: "USD" },
      //       references: {
      //         merchantOrderId: null,
      //         merchantReference: null,
      //         paymentReference: "0",
      //         providerId: null,
      //         providerReference: null,
      //         referenceOrigPayment: null,
      //       },
      //     },
      //     status: "PENDING_APPROVAL",
      //     statusOutput: {
      //       isAuthorized: true,
      //       isRefundable: false,
      //       threeDSecureStatus: null,
      //       errors: null,
      //       isCancellable: true,
      //       statusCategory: "PENDING_MERCHANT",
      //       statusCode: 600,
      //       statusCodeChangeDateTime: "20201215215255",
      //     },
      //     id: "000000125600000001510000100001",
      //   },
      //   paymentCreationReferences: {
      //     additionalReference: "00000012560000000151",
      //     externalReference: "000000125600000001510000100001",
      //   },
      //   paymentStatusCategory: "SUCCESSFUL",
      //   tokenizationSucceeded: null,
      //   tokens: null,
      // },
    },
    showReceipt() {
      // setReceipt Fields
      // show Receipt
    },
  },
};
</script>
